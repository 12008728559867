@tailwind base;
@tailwind components;
@tailwind utilities;

.card_base {
    @apply flex flex-col bg-white rounded-3xl space-y-4;
}

.card_padding_default {
    @apply p-4 md:p-8;
}

.card_padding_none {
    @apply p-0;
}

.card_border_default {
    @apply border border-secondaryColor;
}

.card_border_error {
    @apply border-2 border-errorColor;
}

.card_border_is_selected {
    @apply border-2 border-primaryButton;
}

.card_hover_shadow {
    @apply hover:outline-none hover:shadow-lg;
}

/*
  css for specific selectable card types for panels, installation and roofcover
*/

.selectable_card_base {
    @apply flex flex-col bg-white rounded-3xl h-full text-left space-y-4 border-2 min-w-[220px] md:min-w-[320px] p-[16px] md:p-[24px];
}

.selectable_card_PANEL {
    @apply max-w-[350px];
}

.selectable_card_MOUNTING {
    @apply max-w-[320px];
}

.selectable_card_ROOF_COVERING {
    @apply max-w-[220px] md:max-w-[320px];
}
