@import url("card.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #__next, main {
 height: 100%;
}

.rdp .rdp-day_selected {
  @apply bg-primaryColor accent-primaryColor;
}

.rdp .rdp-day_selected:hover {
  @apply bg-primaryButton accent-primaryButton;
}
